import React from "react";
import PropTypes from "prop-types";
import * as coreTheme from "@bit/cpinnix.verious.theme";

function createAppearance({ theme }) {
  return {
    wrapper: {
      style: {
        backgroundColor: theme?.wrapper?.backgroundColor,
      },
    },
    container: {
      className: `
        container
        mx-auto
        px-8
        md:px-16
        py-32
      `,
    },
    title: {
      className: `
        text-5xl
        leading-tight
        mb-2
        font-bold
      `,
      style: {
        color: theme?.title?.color,
        fontFamily: theme?.title?.fontFamily,
      },
    },
    subtitle: {
      className: `
        text-xl
        leading-tight
      `,
    },
  };
}

export default function OneLineHero({ title, subtitle, theme }) {
  const appearance = createAppearance({ theme });

  return (
    <div {...appearance.wrapper}>
      <div {...appearance.container}>
        <h1 {...appearance.title}>{title}</h1>
        <p {...appearance.subtitle}>{subtitle}</p>
      </div>
    </div>
  );
}

OneLineHero.propTypes = {
  theme: PropTypes.shape({
    wrapper: PropTypes.shape({
      backgroundColor: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.shape({
      color: PropTypes.string.isRequired,
      fontFamily: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
