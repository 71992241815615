import React from "react";
import PropTypes from "prop-types";

function createAppearance() {
  return {
    wrapper: {
      className: `
        shadow-lg
        rounded
        overflow-hidden
      `,
    },
    icon: {
      className: `
        py-24
        grid
        justify-center
      `,
    },
    credit: {
      className: `
        p-8
      `,
    },
  };
}

export default function IconCreditCard(props) {
  const { icon, credit } = props;
  const appearance = createAppearance(props);
  return (
    <div {...appearance.wrapper}>
      <div {...appearance.icon}>{icon}</div>
      <div {...appearance.credit}>{credit}</div>
    </div>
  );
}

IconCreditCard.defaultProps = {};

IconCreditCard.propsTypes = {};
