import React from "react";

function createAppearance() {
  return {
    layout: {
      className: `
        grid
        gap-8
        md:grid-cols-12
      `,
    },
    item: {
      className: `
        md:col-span-6
        lg:col-span-6
        xl:col-span-4
      `,
    },
  };
}

export default function EvenGridLayout({ items }) {
  const appearance = createAppearance();

  return (
    <div {...appearance.layout}>
      {items.map((item, index) => (
        <div key={index} {...appearance.item}>
          {item}
        </div>
      ))}
    </div>
  );
}
