import React from "react";
import PropTypes from "prop-types";

function createAppearance({ collapseOnMobile }) {
  return {
    container: {
      className: `
        container
        mx-auto
        ${collapseOnMobile ? "" : "px-8"}
        md:px-16
      `,
    },
  };
}

export default function ContainerLayout(props) {
  const { children } = props;
  const appearance = createAppearance(props);
  return <div {...appearance.container}>{children}</div>;
}

ContainerLayout.defaultProps = {
  collapseOnMobile: false,
};

ContainerLayout.propsTypes = {};
