import React from "react";
import PageWrapper from "components/PageWrapper";
import OneLineHeroBlock from "components/OneLineHeroBlock";
import HeadingBlock from "components/HeadingBlock";
import ContainerLayout from "components/ContainerLayout";
import EvenGridLayout from "components/EvenGridLayout";
import IconCreditCard from "components/IconCreditCard";
import TennisBall from "components/TennisBall";
import Bone from "components/Bone";
import * as coreTheme from "@bit/cpinnix.verious.theme";
import * as siteTheme from "theme";

export default function Page() {
  return (
    <PageWrapper>
      <OneLineHeroBlock
        title="Credits"
        theme={{
          wrapper: {
            backgroundColor: coreTheme.gray[100],
          },
          title: {
            color: siteTheme.heading.primary,
            fontFamily: siteTheme.font.primary,
          },
        }}
      />
      <div className="h-24" />
      <HeadingBlock
        level={2}
        theme={{
          color: siteTheme.heading.primary,
          fontFamily: siteTheme.font.primary,
        }}
      >
        Icons
      </HeadingBlock>
      <div className="h-8" />
      <ContainerLayout>
        <EvenGridLayout
          items={[
            <IconCreditCard
              icon={<TennisBall size={96} />}
              credit={<div>Created by Adiyogi from the Noun Project.</div>}
            />,
            <IconCreditCard
              icon={<Bone size={96} color={coreTheme.black} />}
              credit={<div>Created by @w@n !cons from the Noun Project.</div>}
            />,
          ]}
        />
      </ContainerLayout>
      <div className="h-24" />
    </PageWrapper>
  );
}
