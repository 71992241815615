import React from "react";
import PropTypes from "prop-types";

export default function TennisBall(props) {
  const { color, size } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 45 45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.40626 39.1518C11.5283 42.9226 16.9149 45.0095 22.5013 45C22.8633 45 23.2273 44.9914 23.5915 44.9741C28.0314 44.7635 32.3091 43.2409 35.8836 40.5988C39.4582 37.9568 42.1691 34.314 43.6736 30.1311C45.178 25.9482 45.4084 21.4131 44.3356 17.0993C43.2628 12.7855 40.935 8.88666 37.6466 5.89591C35.4651 3.8991 32.9109 2.35252 30.1306 1.34483C27.3503 0.337131 24.3984 -0.111863 21.4442 0.0235826C18.4901 0.159028 15.5917 0.87625 12.9152 2.13413C10.2387 3.39202 7.8368 5.16583 5.84713 7.35388C3.85746 9.54193 2.31916 12.1012 1.32042 14.885C0.321678 17.6688 -0.11787 20.6223 0.0269732 23.5763C0.171817 26.5303 0.898204 29.4267 2.16449 32.0993C3.43079 34.772 5.21208 37.1685 7.40626 39.1514V39.1518ZM8.92258 37.4846C5.16961 34.0903 2.83684 29.4038 2.39116 24.363C7.45172 24.3254 12.3389 26.2049 16.0704 29.6238C19.827 33.0151 22.1606 37.7029 22.6021 42.7447C17.5424 42.778 12.6567 40.8993 8.92258 37.4846ZM36.1303 7.5631C39.8831 10.9572 42.2159 15.6436 42.6617 20.6842C37.6013 20.7214 32.7143 18.8421 28.9824 15.4238C25.2259 12.0325 22.8923 7.34474 22.4507 2.3029C22.4835 2.3029 22.5165 2.30167 22.5491 2.30167C27.5751 2.29327 32.4214 4.17076 36.1303 7.5631ZM7.56703 8.91877C10.8476 5.29068 15.3401 2.98559 20.2003 2.43663C20.417 4.94408 21.0553 7.39696 22.0884 9.69188C23.3509 12.5033 25.1819 15.0228 27.4659 17.0917C29.7336 19.1626 32.4041 20.7433 35.3105 21.7349C37.6445 22.5342 40.0948 22.9416 42.5619 22.9405C42.6218 22.9405 42.6818 22.9396 42.7418 22.9391C42.6416 27.8269 40.7741 32.5129 37.4852 36.1297C34.1962 39.7465 29.7084 42.0491 24.8525 42.6115C24.3652 36.9849 21.77 31.7502 17.5869 27.9561C13.4142 24.1507 7.95795 22.0625 2.31105 22.11C2.3973 17.2193 4.26635 12.5284 7.56703 8.91877Z"
        fill={color}
      />
    </svg>
  );
}

TennisBall.propsTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
