import React, { createElement } from "react";
import PropTypes from "prop-types";

function createAppearance({ level, theme }) {
  const sizes = ["text-5xl", "text-4xl", "text-3xl"];

  return {
    container: {
      className: `
        container
        mx-auto
        px-8
        md:px-16
      `,
    },
    heading: {
      className: `
        ${sizes[level]}
        font-bold
      `,
      style: {
        color: theme?.color,
        fontFamily: theme?.fontFamily,
      },
    },
  };
}
export default function HeadingBlock(props) {
  const { level, children } = props;
  const appearance = createAppearance(props);

  return (
    <div {...appearance.container}>
      {createElement(`h${level}`, { ...appearance.heading }, children)}
    </div>
  );
}

HeadingBlock.propTypes = {
  theme: PropTypes.shape({
    fontFamily: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
};
